import request from "@/core/services/axios";

export function provisionalList(data) {
  return request({
    url: "/exam/examstudentscore/get/provisional/list",
    method: "post",
    data: data,
  });
}
// 临时榜-单科查询-根据用户权限返回科目列表
export function getSubjectlist(data) {
  return request({
    url: "/exam/exam/getpermission/subjectlist",
    method: "get",
    params: data,
  });
}

export function importProvisionalList(data) {
  return request({
    url: "/exam/examstudentscore/import/provisional/list",
    method: "post",
    data: data,
  });
}

// 临时榜 —— 检查异常成绩
export function checkProvisionalList(data) {
  return request({
    url: "/exam/examstudentscore/check/provisional/list",
    method: "post",
    data: data,
  });
}
