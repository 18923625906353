<template>
  <div class="tipsItem">
    <template v-for="(v, i) in indexTips.tipsItem">
      <div
        v-if="v.show"
        :key="i"
        :class="{
          'tips-primary': v.type == 'primary',
          'tips-warning': v.type == 'warning',
          'tips-danger': v.type == 'danger',
        }"
        class="tips-item-box"
      >
        <div class="title-box">
          <i v-if="v.type == 'primary'" class="el-icon-question"></i>
          <i v-if="v.type == 'warning'" class="el-icon-warning"></i>
          <img
            v-if="v.type == 'danger'"
            src="@/assets/newExam/icon-err.svg"
            alt=""
          />
          <span v-if="v.title" class="title-html" v-html="v.title"></span>
          <i class="el-icon-close" @click="closeItem(i)"></i>
        </div>
        <div v-if="v.content" class="cnt-box" v-html="v.content"></div>
      </div>
    </template>
  </div>
</template>

<script>
import { getStore, setStore } from "@/core/util/store";
export default {
  name: "TipsItem",
  components: {},
  props: {
    tipsData: {
      type: Array,
      default() {},
    },
  },
  data() {
    return {
      indexTips: {
        show: true,
        id: "",
        tipsItem: [],
      },
    };
  },
  created() {
    this.init();
  },
  mounted() {
    // 注册事件
    this.bus.$on("updateData", () => {
      // console.log(content);
      this.init();
    });
  },
  // 注册的总线事件要在组件销毁时卸载，否则会多次挂载，造成触发一次但多个响应的情况
  beforeDestroy() {
    this.bus.$off("updateData", () => {});
  },
  methods: {
    tapBus() {
      // 触发兄弟组件身上的事件，并传一个object过去
      this.bus.$emit("update", { loading: false });
    },

    closeItem(i) {
      this.indexTips.show = false;
      this.indexTips.tipsItem[i].show = false;
      let tipsObj = getStore({ name: "tipsObj" });
      let pathId = this.$route.path;
      tipsObj[pathId] = this.indexTips;
      setStore({ name: "tipsObj", content: tipsObj });
      this.tapBus();
    },
    // 初始化页面  判断 是否存在 当前路由的提示  如果不存在 就添加
    init() {
      let pathId = this.$route.path;
      let tipsObj = getStore({ name: "tipsObj" });
      if (!this.indexTips.id) {
        this.indexTips.id = pathId;
        let tipsItem = JSON.parse(JSON.stringify(this.tipsData));
        this.indexTips.tipsItem = tipsItem.map((item) => {
          item.show = true;
          return item;
        });
      }
      if (tipsObj) {
        if (tipsObj[pathId]) {
          if (tipsObj[pathId].tipsItem.length == this.tipsData.length) {
            this.tipsData.map((item, itemk) => {
              tipsObj[pathId].tipsItem.map((index, indexK) => {
                if (itemk == indexK) {
                  Object.assign(index, item);
                }
              });
            });
            this.indexTips = tipsObj[pathId];
          } else {
            this.indexTips.id = pathId;
            let tipsItem = JSON.parse(JSON.stringify(this.tipsData));
            this.indexTips.tipsItem = tipsItem.map((item) => {
              item.show = true;
              return item;
            });
            tipsObj[pathId] = this.indexTips;
          }
        } else {
          tipsObj[pathId] = this.indexTips;
        }

        setStore({ name: "tipsObj", content: tipsObj });
      } else {
        let data = {};
        data[pathId] = this.indexTips;
        setStore({ name: "tipsObj", content: data });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.tipsItem {
  .tips-item-box {
    background: #f3f8ff;
    padding: 10px;
    color: #777473;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 2px;
    margin-bottom: 10px;
    img {
      vertical-align: text-bottom;
    }
    .cnt-box {
      width: 100%;
      padding-left: 22px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .title-box {
      width: 100%;
      .title-html {
        margin-left: 8px;
      }
      .el-icon-close {
        float: right;
        line-height: 19px;
        cursor: pointer;
      }
    }
  }
  .tips-primary {
    .el-icon-question {
      color: #4193e0;
    }
  }
  .tips-warning {
    background: #fdf6ec;
    .el-icon-warning {
      color: #fe8516;
    }
  }
  .tips-danger {
    background: #fff3f3;
  }
}
</style>
