<template>
  <div v-if="!indexTips.show" class="tipsIcon">
    <el-popover placement="top" trigger="hover" popper-class="el-popover-edit">
      <template v-for="(v, i) in indexTips.tipsItem">
        <div v-if="!v.show" :key="i" class="title-box">
          <i
            v-if="v.type == 'primary'"
            style="margin-right: 6px"
            class="el-icon-question"
          ></i>
          <i
            v-if="v.type == 'warning'"
            style="margin-right: 6px"
            class="el-icon-warning"
          ></i>
          <img
            v-if="v.type == 'danger'"
            style="margin-right: 6px"
            src="@/assets/newExam/icon-err.svg"
            alt=""
          />
          <span
            v-if="indexShowTips.title"
            class="title-html"
            v-html="indexShowTips.title"
          ></span>
        </div>
      </template>
      <div
        slot="reference"
        class="edit-tips-btn-box"
        :class="{
          'tips-primary': indexShowTips.type == 'primary',
          'tips-warning': indexShowTips.type == 'warning',
          'tips-danger': indexShowTips.type == 'danger',
        }"
        @click="showIndex"
      >
        <i v-if="indexShowTips.type == 'primary'" class="el-icon-question"></i>
        <i v-if="indexShowTips.type == 'warning'" class="el-icon-warning"></i>
        <img
          v-if="indexShowTips.type == 'danger'"
          src="@/assets/newExam/icon-err.svg"
          alt=""
        />
      </div>
    </el-popover>
  </div>
</template>

<script>
import { getStore, setStore } from "@/core/util/store";
export default {
  name: "TipsIcon",
  components: {},
  props: {
    tipsData: {
      type: Array,
      default() {},
    },
  },
  data() {
    return {
      indexTips: {
        show: true,
        id: "",
        tipsItem: [],
      },
      indexShowTips: {},
    };
  },
  created() {
    this.init();
  },
  mounted() {
    // 注册事件
    this.bus.$on("update", () => {
      //   console.log(content);
      this.init();
    });
  },
  // 注册的总线事件要在组件销毁时卸载，否则会多次挂载，造成触发一次但多个响应的情况
  beforeDestroy() {
    this.bus.$off("update", () => {});
  },
  methods: {
    tapBus() {
      // 触发兄弟组件身上的事件，并传一个object过去
      this.bus.$emit("updateData", { loading: false });
    },
    // 初始化页面  判断 是否存在 当前路由的提示  如果不存在 就添加
    init() {
      let pathId = this.$route.path;
      let tipsObj = getStore({ name: "tipsObj" });
      if (!this.indexTips.id) {
        this.indexTips.id = pathId;
        let tipsItem = JSON.parse(JSON.stringify(this.tipsData));
        this.indexTips.tipsItem = tipsItem.map((item) => {
          item.show = true;
          return item;
        });
      }
      if (tipsObj) {
        if (tipsObj[pathId]) {
          if (tipsObj[pathId].tipsItem.length == this.tipsData.length) {
            this.tipsData.map((item, itemk) => {
              tipsObj[pathId].tipsItem.map((index, indexK) => {
                if (itemk == indexK) {
                  Object.assign(index, item);
                }
              });
            });
            this.indexTips = tipsObj[pathId];
          } else {
            this.indexTips.id = pathId;
            let tipsItem = JSON.parse(JSON.stringify(this.tipsData));
            this.indexTips.tipsItem = tipsItem.map((item) => {
              item.show = true;
              return item;
            });
            tipsObj[pathId] = this.indexTips;
          }
        } else {
          tipsObj[pathId] = this.indexTips;
        }

        setStore({ name: "tipsObj", content: tipsObj });
      } else {
        let data = {};
        data[pathId] = this.indexTips;
        setStore({ name: "tipsObj", content: data });
      }
      let res = this.indexTips.tipsItem.filter(
        (item) => item.type == "primary" && item.show == false
      );
      if (res.length > 0) {
        this.indexShowTips = res[0];
      }
      res = this.indexTips.tipsItem.filter(
        (item) => item.type == "warning" && item.show == false
      );
      if (res.length > 0) {
        this.indexShowTips = res[0];
      }
      res = this.indexTips.tipsItem.filter(
        (item) => item.type == "danger" && item.show == false
      );
      if (res.length > 0) {
        this.indexShowTips = res[0];
      }
    },
    showIndex() {
      this.indexTips.show = true;
      this.indexTips.tipsItem.map((item) => {
        item.show = true;
      });
      let tipsObj = getStore({ name: "tipsObj" });
      let pathId = this.$route.path;
      tipsObj[pathId] = this.indexTips;
      setStore({ name: "tipsObj", content: tipsObj });
      this.tapBus();
    },
  },
};
</script>
<style lang="scss">
.el-popover-edit {
  .title-box {
    border-top: 1px solid #ffffff;
    &:first-child {
      border-top: 0;
    }
  }
  .el-icon-question {
    color: #4193e0;
  }
  .el-icon-warning {
    color: #fe8516;
  }
}
</style>
<style scoped lang="scss">
.tipsIcon {
  .tips-warning {
    background: #fdf6ec;
    .el-icon-warning {
      color: #fe8516;
    }
  }
  .tips-danger {
    background: #fff3f3;
  }
}
</style>
